<template>
    <b-overlay :show="loading">
        <b-tabs pills
            nav-class="mb-2">
            <b-tab title="Detail"
              active>
              <b-row class="justify-content-center">
                  <b-col lg="9" md="10" sm="12">
                      <b-card>
                          <!-- <div class="d-flex justify-content-end mb-2">
                              <b-button @click.prevent="confirmCancel" variant="outline-danger">Batalkan Penyimpanan?</b-button>
                          </div> -->
                          <table class="table">
                              <tr>
                                  <td>Nama Aset</td>
                                  <th>{{ penyimpanan.asset ? penyimpanan.asset.nama : '' }}</th>
                              </tr>
                              <tr>
                                  <td>Kode</td>
                                  <th>{{ penyimpanan.asset ? penyimpanan.asset.kode : '' }}</th>
                              </tr>
                              <tr>
                                  <td>Jenis Aset</td>
                                  <th>
                                      <div v-if="penyimpanan.asset">
                                          {{ penyimpanan.asset.jenis == 1 ? 'TETAP' : 'BHP' }}
                                      </div>
                                  </th>
                              </tr>
                              <tr>
                                  <td>Kategori</td>
                                  <th>
                                      <div v-if="penyimpanan.asset">
                                          {{ penyimpanan.asset.kategori ? penyimpanan.asset.kategori.kategori : '' }}
                                      </div>
                                  </th>
                              </tr>
                              <tr>
                                  <td>Umur</td>
                                  <th>{{ penyimpanan.asset ? penyimpanan.asset.umur : '' }}</th>
                              </tr>
                              <tr>
                                  <td>Volume</td>
                                  <th>{{ penyimpanan.asset ? penyimpanan.asset.volume : '' }}</th>
                              </tr>
                              <tr>
                                  <td>Harga Dasar </td>
                                  <th>Rp {{ penyimpanan.asset ? formatRupiah(penyimpanan.asset.harga_dasar) : 0 }}</th>
                              </tr>
                              <tr>
                                  <td>Keterangan</td>
                                  <th>{{ penyimpanan.asset ? penyimpanan.asset.keterangan : '' }}</th>
                              </tr>
                              <tr>
                                  <td>Stok</td>
                                  <th>{{ penyimpanan.stok }}</th>
                              </tr>
                              <tr>
                                  <td>Satuan</td>
                                  <th>
                                      <div v-if="penyimpanan.asset">
                                          {{ penyimpanan.asset.satuan ? penyimpanan.asset.satuan.satuan : '' }}
                                      </div>
                                  </th>
                              </tr>
                              <tr>
                                  <td>Lokasi Gudang</td>
                                  <th>{{ penyimpanan.gudang ? penyimpanan.gudang.nama_gudang : '' }}</th>
                              </tr>
                              <tr>
                                  <td>Lokasi Aset</td>
                                  <th>{{ penyimpanan.blok ? penyimpanan.blok.blok : '' }}</th>
                              </tr>
                          </table>
                      </b-card>
                  </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Riwayat Penyimpanan">
                <table-riwayat-penyimpanan @showLoading="toggleLoading" />
            </b-tab>
        </b-tabs>
    </b-overlay>
</template>
<script>
    import {BTab, BTabs, BOverlay, BRow, BCol, BCard, BButton} from 'bootstrap-vue'
    import TableRiwayatPenyimpanan from './components/Riwayat.vue'
    export default {
        components: {
            BTab, BTabs, BOverlay, BRow, BCol, BCard, BButton, TableRiwayatPenyimpanan
        },
        data: () => ({
            histories: [], // untuk data riwayat penyimpanan
            loading: false,
            penyimpanan: {},
            confirmOption: {
                title: 'Anda yakin?',
                text: "Stok sebelumnya akan dikembalikan dari penyimpanan saat ini",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false
            }
        }),
        methods: {
            toggleLoading(value) {
                this.loading = value
            },
            async getRiwayatPenyimpanan() {
                
                const params = { id_penyimpanan: this.$route.params.id }
                const histories = await this.$store.dispatch('asset/getRiwayatPenyimpanan', params)
            },
            confirmCancel() {
                this.$swal(this.confirmOption)
                .then(res => {
                    if(res.value) {
                        this.doCancelPenyimpanan()
                    }
                })
            },
            async doCancelPenyimpanan() {
                const payload = {
                    id: this.$route.params.id,
                    cancel: 1
                }

                try {
                    this.loading = true
                    await this.$store.dispatch('asset/cancelPenyimpanan', [payload])
                    this.loading = false
                    this.displaySuccess({
                        message: 'Stok Aset berhasil dicancel'
                    })
                    setTimeout(() => {
                        this.$router.push(`/asset-penyimpanan`)
                    }, 500)
                }
                catch(e) {
                    this.loading = false
                    this.displayError(e)
                    return false
                }
            }
        },
        created() {
            const penyimpanan = this.$store.getters['asset/searchStock'](this.$route.params.id)
            if(!penyimpanan) {
                this.$router.push('/asset-penyimpanan')
            }
            
            this.penyimpanan = penyimpanan
            this.getRiwayatPenyimpanan()
        }
    }
</script>