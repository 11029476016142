<template>
    <b-card>
        <b-row v-if="histories.length > 0">
            <b-col cols="12" class="mb-2">
                <b-button variant="outline-danger" :disabled="dataSelected.length < 1" @click.prevent="confirmCancel">Batalkan Penyimpanan <span v-if="dataSelected.length > 0">({{dataSelected.length}})</span></b-button>
            </b-col>
            <b-col md="6" sm="4" class="my-1">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
                <b-form-group
                    :label="$t('Filter')"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                >
                    <b-input-group size="sm">
                    <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        
                    />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                    </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-table
                    striped
                    hover
                    responsive
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="histories"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                >
                    <template #head(checkbox)>
                        <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="{item}">
                        <b-form-checkbox v-model="dataSelected" :value="item"></b-form-checkbox>
                    </template>
                    <template #cell(no)="{index}">
                        {{++index}}
                    </template>
                    <template #cell(nama)="{item}">
                        <strong v-if="item.asset">
                            <router-link :to="`/asset/detail/${item.id}`">{{item.asset.nama}}</router-link>
                        </strong>
                        <i class="text-danger" v-else>Aset tidak ditemukan</i>
                    </template>
                    <template #cell(kode)="{item}">
                        <span v-if="item.asset">
                            {{item.asset.kode}}
                        </span>
                        <i class="text-danger" v-else>kode tidak ditemukan</i>
                    </template>
                    <template #cell(satuan)="{item}">
                        <span v-if="item.asset && item.asset.satuan">
                            {{item.asset.satuan.satuan}}
                        </span>
                        <i class="text-danger" v-else>Satuan tidak ditemukan</i>
                    </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    size="sm"
                    class="my-0"
                />
            </b-col>
        </b-row>
        <div class="alert alert-danger p-1" v-else>Riwayat Penyimpanan aset ini belum ada.</div>
    </b-card>
</template>
<script>
    import {BButton, BCard, BRow, BCol, BPagination, BTable, BFormInput, BFormCheckbox, BFormGroup, BInputGroup, BInputGroupAppend, BFormSelect} from 'bootstrap-vue'
    import {mapState} from 'vuex'
    export default {
        watch: {
            allChecked(val) {
                if(val) {
                    this.dataSelected = this.histories
                }
                else {
                    this.dataSelected = []
                }
            }
        },
        components: {
            BButton, BCard, BRow, BCol, BPagination, BTable, BFormInput, BFormCheckbox, BFormGroup, BInputGroup, BInputGroupAppend, BFormSelect
        },
        data: () => ({
            allChecked: false,
            dataSelected: [],
            fields: [
                {key: 'checkbox', label: '#'},
                {key: 'no', label: 'No', sortable: true},
                {key: 'nama', label: 'Aset', sortable: true},
                {key: 'kode', label: 'Kode'},
                {key: 'jumlah', label: 'Stok'},
                {key: 'satuan', label: 'Satuan'}
            ],
            perPage: 10,
            pageOptions: [10, 30, 50],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "desc",
            filter: null,
            filterOn: [],
            confirmOption: {
                title: 'Anda yakin?',
                text: "Anda akan membatalkan penyimpanan aset",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false
            }
        }),
        computed: {
            ...mapState('asset', ['histories']),
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({ text: f.label, value: f.key }));
            }
        },
        methods: {
            confirmCancel() {
                this.$swal(this.confirmOption)
                .then(res => {
                    if(res.value) {
                        this.doCancel()
                    }
                })
            },
            async doCancel() {
                const histories = JSON.parse( JSON.stringify(this.histories) )
                const payloads = this.dataSelected.map(item => ({id: item.id, cancel: 1}))
                
                try {
                    this.$emit('showLoading', true)
                    await this.$store.dispatch('asset/cancelPenyimpanan', payloads)
                    this.$emit('showLoading', false)
                    this.displaySuccess({
                        message: 'Penyimpanan Aset berhasil dibatalkan'
                    })
                    setTimeout(() => this.$router.push('/asset-penyimpanan'), 1000)
                }
                catch(e) {
                    this.$emit('showLoading', false)
                    this.displayError(e)
                    return false
                }
            }
        }
    }
</script>